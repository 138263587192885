
import Vue from 'vue';
import qr from '@/assets/images/qr.png';
import websiteQrCode from '@/assets/images/products/svg/websiteQrCode.svg';
import { eInteractionDeviceTypes, eModeType } from '@/enums';
import initializeSitchButtons from 'sitch-embed';
import { format } from 'date-fns';
import { parse } from 'papaparse';
import validationRules from '@/validation-rules';
import blackSitchCard from '@/assets/images/products/bwf.png';
import blackSilverSitchCard from '@/assets/images/products/bsf.png';
import blackGoldSitchCard from '@/assets/images/products/bgf.png';
import whiteSitchCard from '@/assets/images/products/wbf.png';
import whiteSilverSitchCard from '@/assets/images/products/wsf.png';
import whiteGoldSitchCard from '@/assets/images/products/wgf.png';
import blackChatSitchCard from '@/assets/images/products/bwc.png';
import blackSilverChatSitchCard from '@/assets/images/products/bsc.png';
import blackGoldChatSitchCard from '@/assets/images/products/bgc.png';
import whiteChatSitchCard from '@/assets/images/products/wbc.png';
import whiteSilverChatSitchCard from '@/assets/images/products/wsc.png';
import whiteGoldChatSitchCard from '@/assets/images/products/wgc.png';
import blackWhiteCustomLogoSitchCard from '@/assets/images/products/bwl.png';
import blackSilverCustomLogoSitchCard from '@/assets/images/products/bsl.png';
import blackGoldCustomLogoSitchCard from '@/assets/images/products/bgl.png';
import whiteCustomLogoSitchCard from '@/assets/images/products/wbl.png';
import whiteSilverCustomLogoSitchCard from '@/assets/images/products/wsl.png';
import whiteGoldCustomLogoSitchCard from '@/assets/images/products/wgl.png';
import square16mmx16mmStickerPackSize5 from '@/assets/images/products/square16mmx16mmStickerPackSize5_200px-min.png';
import sitchBand from '@/assets/images/products/sitchBand200px-min.png';
import sitchDot from '@/assets/images/products/sitchDot200px-min.png';
import sitchBumper from '@/assets/images/products/sitchBumper200px-min.png';
import umbCard from '@/assets/images/products/umbCard.png';
import { endpoints, getEmptyLinkedDevice } from '@/constants';
import { deleteField, doc, getDoc, Timestamp, updateDoc, writeBatch } from 'firebase/firestore';
import { standardApiFetch, currFirestore, sitchClientUrl, fbAuth } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showError } from '@/util-functions/misc-firestore-utils';
import { isFeatureEnabled, getModeIcon } from '@/util-functions/misc-utils';
import { showSuccess, showConfirmation, showNotice } from '@/util-functions/notice-utils';
import { getUserDoc, getUserModeGatewayDoc, updateUserLocal } from '@/util-functions/user-utils';
import { hideLoading, showLoading } from '@/util-functions/loading-utils';
const sitchClientBaseUrl = 'https://sitch.app';

const cardName = 'card';
const bandName = 'band';
const bumperName = 'bumper';
const dotName = 'dot';
const stickerName = 'sticker';
const maxDevicesPerPage = 15;

const products = [cardName, bandName, bumperName, dotName, stickerName];

enum eViewTypes {
  gallery,
  list,
}

enum eSortTypes {
  name,
  dateActivated,
}

export default Vue.extend({
  data(): {
    qr: typeof qr;
    animationTimeout: number;
    cardFlipped: boolean;
    productTapping: boolean;
    cardTapFinished: boolean;
    turnOnPhone: boolean;
    iframeSrc: string;
    iframeSrcToSet: string;
    selectedIndex: number | null;
    storeLink: string;
    blackSitchCard: string;
    blackSilverSitchCard: string;
    blackGoldSitchCard: string;
    whiteSitchCard: string;
    whiteSilverSitchCard: string;
    whiteGoldSitchCard: string;
    blackChatSitchCard: string;
    blackSilverChatSitchCard: string;
    blackGoldChatSitchCard: string;
    whiteChatSitchCard: string;
    whiteSilverChatSitchCard: string;
    whiteGoldChatSitchCard: string;
    blackWhiteCustomLogoSitchCard: string;
    blackSilverCustomLogoSitchCard: string;
    blackGoldCustomLogoSitchCard: string;
    whiteCustomLogoSitchCard: string;
    whiteSilverCustomLogoSitchCard: string;
    whiteGoldCustomLogoSitchCard: string;
    square16mmx16mmStickerPackSize5: string;
    sitchBand: string;
    sitchDot: string;
    sitchBumper: string;
    umbCard: string;
    eInteractionDeviceTypes: typeof eInteractionDeviceTypes;
    modeIdToAssignToAll: string;
    showPartsFor: { [id: string]: boolean };
    currProduct: number;
    products: string[];
    uniqueViews: { [id: string]: boolean };
    websiteQrCode: string;
    cardName: string;
    bumperName: string;
    bandName: string;
    dotName: string;
    stickerName: string;
    showAddDevicesFromCsvDialog: boolean;
    uploadedDeviceCsvFile: Blob | null;
    eViewTypes: typeof eViewTypes;
    eSortType: typeof eSortTypes;
    viewType: eViewTypes;
    sortType: eSortTypes;
    deviceTableHeaders: TableHeaders;
    currPage: number;
  } {
    return {
      qr,
      animationTimeout: 0,
      cardFlipped: false,
      productTapping: false,
      cardTapFinished: false,
      turnOnPhone: false,
      iframeSrc: '',
      iframeSrcToSet: '',
      selectedIndex: null,
      storeLink: `${sitchClientBaseUrl}/store`,
      blackSitchCard,
      blackSilverSitchCard,
      blackGoldSitchCard,
      whiteSitchCard,
      whiteSilverSitchCard,
      whiteGoldSitchCard,
      blackChatSitchCard,
      blackSilverChatSitchCard,
      blackGoldChatSitchCard,
      whiteChatSitchCard,
      whiteSilverChatSitchCard,
      whiteGoldChatSitchCard,
      blackWhiteCustomLogoSitchCard,
      blackSilverCustomLogoSitchCard,
      blackGoldCustomLogoSitchCard,
      whiteCustomLogoSitchCard,
      whiteSilverCustomLogoSitchCard,
      whiteGoldCustomLogoSitchCard,
      square16mmx16mmStickerPackSize5,
      sitchBand,
      sitchDot,
      sitchBumper,
      umbCard,
      eInteractionDeviceTypes,
      modeIdToAssignToAll: '',
      showPartsFor: {},
      currProduct: 0,
      products,
      uniqueViews: {},
      websiteQrCode,
      cardName,
      bumperName,
      bandName,
      dotName,
      stickerName,
      showAddDevicesFromCsvDialog: false,
      uploadedDeviceCsvFile: null,
      eViewTypes: eViewTypes,
      viewType: eViewTypes.gallery,
      eSortType: eSortTypes,
      sortType: eSortTypes.dateActivated,
      currPage: 1,
      deviceTableHeaders: [
        {
          text: t.activated,
          value: 'dateActivated',
          sortable: true,
        },
        {
          text: t.name,
          value: 'name',
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.cardFlip();
    const uniqueViewsDoc = doc(currFirestore, getUserDoc().path, 'uniqueViews', 'uniqueViewsDoc');
    getDoc(uniqueViewsDoc)
      .then((uniqueViewsDocSnap) => {
        if (uniqueViewsDocSnap.exists()) {
          this.uniqueViews = uniqueViewsDocSnap.data()?.ids || {};
        }
      })
      .catch((error: any) => {
        showError(`Cannot get unique view data.`, error, true);
      });
    standardApiFetch(endpoints.getCountryFromIp, {})
      .then((response) => {
        const country = response.successfulResponse.countryCode || 'INT';
        const continentCode = response.successfulResponse.continentCode;
        if (continentCode === 'EU') {
          this.storeLink = `${sitchClientBaseUrl}/eu-store`;
        } else {
          switch (country) {
            case 'US':
              this.storeLink = `${sitchClientBaseUrl}/us-store`;
              break;
            case 'CA':
              this.storeLink = `${sitchClientBaseUrl}/ca-store`;
              break;
            case 'UK':
            case 'GB':
              this.storeLink = `${sitchClientBaseUrl}/uk-store`;
              break;
            default:
              this.storeLink = `${sitchClientBaseUrl}/store`;
              break;
          }
        }
      })
      .catch((err) => {
        console.error(err);
        switch (navigator.language) {
          case 'en-US':
            this.storeLink = `${sitchClientBaseUrl}/us-store`;
            break;
          case 'en-CA':
            this.storeLink = `${sitchClientBaseUrl}/ca-store`;
            break;
          case 'en-GB':
            this.storeLink = `${sitchClientBaseUrl}/uk-store`;
            break;
        }
      })
      .finally(() => {
        initializeSitchButtons();
      });
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.linkedDevices.length / maxDevicesPerPage);
    },
    linkedDevicesForCurrentPage(): LinkedDevice[] {
      const index = (this.currPage - 1) * maxDevicesPerPage;
      return this.linkedDevices.slice(index, index + maxDevicesPerPage);
    },
    isAdmin(): boolean {
      return this.$store.state.isAdmin;
    },
    deviceImportsEnabled(): boolean {
      return this.$store.state.featureFlags.deviceImports;
    },
    currUserModeGateway(): PublicUserModeGateway {
      return this.$store.state.currUserModeGateway;
    },
    linkedDevices(): LinkedDevice[] {
      const genericIdsBoundToThisAccount = this.$store.state.currUser.genericIdsBoundToThisAccount;
      let sortFunc = (a: LinkedDevice, b: LinkedDevice) => {
        return a.dateActivated - b.dateActivated;
      };

      switch (this.sortType) {
        case eSortTypes.name:
          sortFunc = (a: LinkedDevice, b: LinkedDevice) => {
            return a.name.trim().localeCompare(b.name.trim());
          };
          break;
      }

      return (Object.values(genericIdsBoundToThisAccount || {}) as LinkedDevice[])
        .map((linkedDevice, index) => {
          return {
            ...getEmptyLinkedDevice(),
            ...linkedDevice,
            index,
          };
        })
        .sort(sortFunc);
    },
    allSelectableModes(): TextValue[] {
      const usedNameCount: { [name: string]: number } = {};
      return [
        {
          text: t.activeMode,
          value: '',
        },
        ...this.$store.getters.teamAndPersonalModesArray.map((mode: Mode | ProccessedTeamMode) => {
          usedNameCount[mode.name] = usedNameCount[mode.name] ? usedNameCount[mode.name] + 1 : 1;
          if (usedNameCount[mode.name] > 1) {
            return {
              text: `${mode.name} (${mode.displayName || usedNameCount[mode.name]})`,
              value: mode.docId,
            };
          }
          return {
            text: mode.name,
            value: mode.docId,
          };
        }),
      ];
    },
    isSitchLinkActivated(): boolean {
      return this.$store.state.currUserModeGateway.isSitchLinkActivated;
    },
    demoModes(): Mode[] {
      const modes: Mode[] = (Object.values(this.$store.state.modes) as Mode[]).filter((mode: Mode) => {
        return ![eModeType.urlRedirect].includes(mode.type as eModeType);
      });
      return modes.slice(0, 8);
    },
  },
  methods: {
    assignToAll() {
      Promise.all(
        this.linkedDevices.map((device) => {
          if (device.isMultiPartDevice) {
            return Promise.resolve(); // Skip the multipart devices
          }
          return this.updateAssignedModeIdFor(this.modeIdToAssignToAll, device, false);
        })
      )
        .then(() => {
          showSuccess(t.updateSuccessful);
          (this.$refs.assignedModeDropdowns as any[]).forEach((dropdown) => {
            dropdown.value = this.modeIdToAssignToAll;
          });
        })
        .catch((error: any) => {
          showError(`Could not update the user.`, error, true);
        });
    },
    getPart(index: number, linkedDevice: LinkedDevice) {
      const part = linkedDevice?.multiPartModeAssignments?.[index];
      return part || {};
    },
    updateNameForDevicePart(index: number, name: string, linkedDevice: LinkedDevice) {
      if (!name) {
        return;
      }
      const isValid = validationRules.requiredNameRules.every((rule) => {
        const result: boolean | string = rule(name);
        if (result === true) {
          return true;
        } else {
          showError(result as string);
          return false;
        }
      });
      if (isValid) {
        updateDoc(getUserDoc(), {
          [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.multiPartModeAssignments.${index}.name`]: name,
        })
          .then(() => {
            showSuccess(t.updateSuccessful);
          })
          .catch((error: any) => {
            showError(`Could not update the user.`, error, true);
          });
      }
    },
    updateNameFor(name: string, linkedDevice: LinkedDevice) {
      if (!name) {
        return;
      }
      const isValid = validationRules.requiredNameRules.every((rule) => {
        const result: boolean | string = rule(name);
        if (result === true) {
          return true;
        } else {
          showError(result as string);
          return false;
        }
      });
      if (isValid) {
        updateDoc(getUserDoc(), {
          [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.name`]: name,
        })
          .then(() => {
            showSuccess(t.updateSuccessful);
          })
          .catch((error: any) => {
            showError(`Could not update the user.`, error, true);
          });
      }
    },
    updateAssignedModeIdForDevicePart(index: number, assignedModeId: string, linkedDevice: LinkedDevice, showMessages = true): Promise<any> {
      return new Promise((resolve, reject) => {
        const modesMap = this.$store.getters.teamAndPersonalModesMap as { [modeId: string]: ProccessedTeamMode | Mode };
        const assignedModeOwnerId = (modesMap[assignedModeId] as ProccessedTeamMode)?.ownerId || '';
        const assignedLinkId = modesMap[assignedModeId]?.linkId || '';
        const batch = writeBatch(currFirestore);

        if (assignedModeId || assignedModeOwnerId || assignedLinkId) {
          batch.update(getUserDoc(), {
            [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.multiPartModeAssignments.${index}.assignedModeId`]: assignedModeId || deleteField(),
            [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.multiPartModeAssignments.${index}.assignedModeOwnerId`]: assignedModeOwnerId || deleteField(),
            [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.multiPartModeAssignments.${index}.assignedLinkId`]: assignedLinkId || deleteField(),
            dateUpdated: Timestamp.fromMillis(Date.now()),
          });
          batch.update(doc(currFirestore, 'genericIdToUserIdMappings', linkedDevice.genericId), {
            [`multiPartModeAssignments.${index}.assignedModeId`]: assignedModeId || deleteField(),
            [`multiPartModeAssignments.${index}.assignedModeOwnerId`]: assignedModeOwnerId || deleteField(),
            [`multiPartModeAssignments.${index}.assignedLinkId`]: assignedLinkId || deleteField(),
            dateUpdated: Timestamp.fromMillis(Date.now()),
          });
        } else {
          // If we're deleting all the properties then just remove the whole object
          batch.update(getUserDoc(), {
            [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.multiPartModeAssignments.${index}`]: deleteField(),
            dateUpdated: Timestamp.fromMillis(Date.now()),
          });
          batch.update(doc(currFirestore, 'genericIdToUserIdMappings', linkedDevice.genericId), {
            [`multiPartModeAssignments.${index}`]: deleteField(),
            dateUpdated: Timestamp.fromMillis(Date.now()),
          });
        }

        batch
          .commit()
          .then(() => {
            if (showMessages) {
              showSuccess(t.updateSuccessful);
            }
            const genericIdsBoundToThisAccount = this.$store.state.currUser.genericIdsBoundToThisAccount;
            const newGenericIdsBoundToThisAccount = {
              ...genericIdsBoundToThisAccount,
              [linkedDevice.genericId]: {
                ...genericIdsBoundToThisAccount[linkedDevice.genericId],
                multiPartModeAssignments: {
                  ...genericIdsBoundToThisAccount[linkedDevice.genericId].multiPartModeAssignments,
                  [index]: {
                    assignedModeId,
                    assignedModeOwnerId,
                    assignedLinkId,
                  },
                },
              },
            };
            updateUserLocal({
              genericIdsBoundToThisAccount: newGenericIdsBoundToThisAccount,
              dateUpdated: Timestamp.fromMillis(Date.now()),
            });
            resolve(true);
          })
          .catch((error: any) => {
            if (showMessages) {
              showError(`Could not update the user.`, error, true);
            }
            reject(error);
          });
      });
    },
    updateAssignedModeIdFor(assignedModeId: string, linkedDevice: LinkedDevice, showMessages = true): Promise<any> {
      return new Promise((resolve, reject) => {
        const modesMap = this.$store.getters.teamAndPersonalModesMap as { [modeId: string]: ProccessedTeamMode | Mode };
        const assignedModeOwnerId = (modesMap[assignedModeId] as ProccessedTeamMode)?.ownerId || '';
        const assignedLinkId = modesMap[assignedModeId]?.linkId || '';
        const batch = writeBatch(currFirestore);

        batch.update(getUserDoc(), {
          [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.assignedModeId`]: assignedModeId,
          [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.assignedModeOwnerId`]: assignedModeOwnerId,
          [`genericIdsBoundToThisAccount.${linkedDevice.genericId}.assignedLinkId`]: assignedLinkId,
          dateUpdated: Timestamp.fromMillis(Date.now()),
        });

        batch.update(doc(currFirestore, 'genericIdToUserIdMappings', linkedDevice.genericId), {
          assignedModeId,
          assignedModeOwnerId,
          assignedLinkId,
          dateUpdated: Timestamp.fromMillis(Date.now()),
        });

        batch
          .commit()
          .then(() => {
            if (showMessages) {
              showSuccess(t.updateSuccessful);
            }
            const genericIdsBoundToThisAccount = this.$store.state.currUser.genericIdsBoundToThisAccount;
            const newGenericIdsBoundToThisAccount = {
              ...genericIdsBoundToThisAccount,
              [linkedDevice.genericId]: {
                ...genericIdsBoundToThisAccount[linkedDevice.genericId],
                assignedModeId,
                assignedModeOwnerId,
                assignedLinkId,
              },
            };
            updateUserLocal({
              genericIdsBoundToThisAccount: newGenericIdsBoundToThisAccount,
              dateUpdated: Timestamp.fromMillis(Date.now()),
            });
            resolve(true);
          })
          .catch((error: any) => {
            if (showMessages) {
              showError(`Could not update the user.`, error, true);
            }
            reject(error);
          });
      });
    },
    previewLink(linkedDevice: LinkedDevice) {
      return `${sitchClientUrl}/?g=${linkedDevice.genericId}`;
    },
    previewLinkForPart(index: string, linkedDevice: LinkedDevice) {
      return `${sitchClientUrl}/?g=${linkedDevice.genericId}&par=${index}`;
    },
    unlinkDevice(linkedDevice: LinkedDevice) {
      showConfirmation(t?.areYouSureYouWantToUnlink, () => {
        const newGenericIdsBoundToThisAccount = {
          ...this.$store.state.currUser.genericIdsBoundToThisAccount,
        };
        delete newGenericIdsBoundToThisAccount[linkedDevice.genericId];

        const batch = writeBatch(currFirestore);

        const genericIdDataUpdate: any = {
          userId: 'TBD',
          dateActivated: deleteField(),
        };

        if (linkedDevice.isMultiPartDevice) {
          // Remove all asssignments since this device might now go to someone new with all new modes.
          genericIdDataUpdate.multiPartModeAssignments = {};
        }

        batch.update(doc(currFirestore, 'genericIdToUserIdMappings', linkedDevice.genericId), genericIdDataUpdate);

        if (!Object.values(newGenericIdsBoundToThisAccount).length) {
          // If they deactivate all of their cards, then deactivate the Sitch Link.
          batch.update(getUserModeGatewayDoc(), {
            isSitchLinkActivated: false,
            dateUpdated: Timestamp.fromMillis(Date.now()),
          });
        }

        batch.update(getUserDoc(), {
          [`genericIdsBoundToThisAccount.${linkedDevice.genericId}`]: deleteField(),
          dateUpdated: Timestamp.fromMillis(Date.now()),
        });

        batch
          .commit()
          .then(() => {
            updateUserLocal({
              genericIdsBoundToThisAccount: newGenericIdsBoundToThisAccount,
              dateUpdated: Timestamp.fromMillis(Date.now()),
            });
            showSuccess(t.updateSuccessful);
          })
          .catch((error: any) => {
            showError(`Could not update the user.`, error, true);
          });
      });
    },
    onAddDevicesFromCsvClick() {
      this.showAddDevicesFromCsvDialog = true;
    },
    getBackgroundImage(type: eInteractionDeviceTypes) {
      switch (type) {
        case eInteractionDeviceTypes.blackSitchCardV2:
        case eInteractionDeviceTypes.blackSitchCard:
          return this.blackSitchCard;
        case eInteractionDeviceTypes.blackSilverSitchCard:
          return this.blackSilverSitchCard;
        case eInteractionDeviceTypes.blackGoldSitchCard:
          return this.blackGoldSitchCard;
        case eInteractionDeviceTypes.whiteSitchCard:
          return this.whiteSitchCard;
        case eInteractionDeviceTypes.whiteSilverSitchCard:
          return this.whiteSilverSitchCard;
        case eInteractionDeviceTypes.whiteGoldSitchCard:
          return this.whiteGoldSitchCard;
        case eInteractionDeviceTypes.blackChatSitchCard:
          return this.blackChatSitchCard;
        case eInteractionDeviceTypes.blackSilverChatSitchCard:
          return this.blackSilverChatSitchCard;
        case eInteractionDeviceTypes.blackGoldChatSitchCard:
          return this.blackGoldChatSitchCard;
        case eInteractionDeviceTypes.whiteChatSitchCard:
          return this.whiteChatSitchCard;
        case eInteractionDeviceTypes.whiteSilverChatSitchCard:
          return this.whiteSilverChatSitchCard;
        case eInteractionDeviceTypes.whiteGoldChatSitchCard:
          return this.whiteGoldChatSitchCard;
        case eInteractionDeviceTypes.whiteCustomLogoSitchCard:
          return this.whiteCustomLogoSitchCard;
        case eInteractionDeviceTypes.whiteSilverCustomLogoSitchCard:
          return this.whiteSilverCustomLogoSitchCard;
        case eInteractionDeviceTypes.whiteGoldCustomLogoSitchCard:
          return this.whiteGoldCustomLogoSitchCard;
        case eInteractionDeviceTypes.blackWhiteCustomLogoSitchCard:
          return this.blackWhiteCustomLogoSitchCard;
        case eInteractionDeviceTypes.blackSilverCustomLogoSitchCard:
          return this.blackSilverCustomLogoSitchCard;
        case eInteractionDeviceTypes.blackGoldCustomLogoSitchCard:
          return this.blackGoldCustomLogoSitchCard;
        case eInteractionDeviceTypes.blackSitchBand:
          return this.sitchBand;
        case eInteractionDeviceTypes.blackSitchDot:
          return this.sitchDot;
        case eInteractionDeviceTypes.blackSitchBumper:
          return this.sitchBumper;
        case eInteractionDeviceTypes.rectangular11mmx26mmStickerPackSize5:
          return this.square16mmx16mmStickerPackSize5;
        case eInteractionDeviceTypes.rectangular11mmx26mmStickerSingle:
          return this.square16mmx16mmStickerPackSize5;
        case eInteractionDeviceTypes.square16mmx16mmStickerPackSize5:
          return this.square16mmx16mmStickerPackSize5;
        case eInteractionDeviceTypes.tapeeo:
          return this.blackSitchCard;
        case eInteractionDeviceTypes.gust:
          return this.whiteSitchCard;
        case eInteractionDeviceTypes.umbCard:
          return this.umbCard;
        default:
          return '';
      }
    },
    formatInteractionDeviceType(type: eInteractionDeviceTypes): string {
      switch (type) {
        case eInteractionDeviceTypes.blackSitchCardV2:
        case eInteractionDeviceTypes.blackSitchCard:
          return 'Black Card';
        case eInteractionDeviceTypes.whiteSitchCard:
          return 'White Card';
        case eInteractionDeviceTypes.whiteChatSitchCard:
          return 'White Chat Card';
        case eInteractionDeviceTypes.blackChatSitchCard:
          return 'Black Chat Card';
        case eInteractionDeviceTypes.whiteCustomLogoSitchCard:
          return 'Color Logo Card';
        case eInteractionDeviceTypes.blackSilverSitchCard:
          return 'Silver on Black Card';
        case eInteractionDeviceTypes.blackGoldSitchCard:
          return 'Gold on Black Card';
        case eInteractionDeviceTypes.whiteSilverSitchCard:
          return 'Silver on White Card';
        case eInteractionDeviceTypes.whiteGoldSitchCard:
          return 'Gold on White Card';
        case eInteractionDeviceTypes.blackSilverChatSitchCard:
          return 'Silver on Black Chat Card';
        case eInteractionDeviceTypes.blackGoldChatSitchCard:
          return 'Gold on Black Chat Card';
        case eInteractionDeviceTypes.whiteSilverChatSitchCard:
          return 'Silver on White Chat Card';
        case eInteractionDeviceTypes.whiteGoldChatSitchCard:
          return 'Gold on White Chat Card';
        case eInteractionDeviceTypes.whiteSilverCustomLogoSitchCard:
          return 'Silver on White Logo Card';
        case eInteractionDeviceTypes.whiteGoldCustomLogoSitchCard:
          return 'Gold on White Logo Card';
        case eInteractionDeviceTypes.blackWhiteCustomLogoSitchCard:
          return 'White on Black Logo Card';
        case eInteractionDeviceTypes.blackSilverCustomLogoSitchCard:
          return 'Silver on Black Logo Card';
        case eInteractionDeviceTypes.blackGoldCustomLogoSitchCard:
          return 'Gold on Black Logo Card';
        case eInteractionDeviceTypes.blackSitchBand:
          return 'Black Sitch Band';
        case eInteractionDeviceTypes.blackSitchDot:
          return 'Black Sitch Dot';
        case eInteractionDeviceTypes.blackSitchBumper:
          return 'Black Sitch Bumper';
        case eInteractionDeviceTypes.blackUnisexShirt:
          return 'Black Unisex Shirt';
        case eInteractionDeviceTypes.blackWomensShirt:
          return 'Black Womens Shirt';
        case eInteractionDeviceTypes.rectangular11mmx26mmStickerPackSize5:
        case eInteractionDeviceTypes.square16mmx16mmStickerPackSize5:
          return 'Sitch Sticker x5';
        case eInteractionDeviceTypes.rectangular11mmx26mmStickerSingle:
          return 'Sitch Sticker';
        case eInteractionDeviceTypes.tapeeo:
          return 'Tapeeo';
        case eInteractionDeviceTypes.smartLink:
          return 'Smart Link';
        case eInteractionDeviceTypes.umbCard:
          return 'UMB card';
        case eInteractionDeviceTypes.zgoda:
          return 'Zgoda Creations Card';
        default:
          return 'Unknown';
      }
    },
    formatDate(date: number): string {
      return date ? format(date, 'MMM dd yyyy h:mm a') : t.notApplicable;
    },
    checkoutRedirect() {
      if (!isFeatureEnabled(this.$store.state.featureFlags.sitchDevices)) {
        showNotice("We're sold out at the moment but it'll be back soon!");
        return;
      }
      window.open('https://sitch.app/store', '_blank');
    },
    showPartsForDevice(linkedDevice: LinkedDevice) {
      this.showPartsFor[linkedDevice.genericId] = !this.showPartsFor[linkedDevice.genericId];
      this.$forceUpdate();
    },
    getPartLengthFor(type: eInteractionDeviceTypes) {
      switch (type) {
        case eInteractionDeviceTypes.rectangular11mmx26mmStickerPackSize5:
        case eInteractionDeviceTypes.square16mmx16mmStickerPackSize5:
          return 5;
      }
      return 0;
    },
    // Demo methods:
    getModeIcon(type: eModeType) {
      return getModeIcon(type);
    },
    onModePick(mode: Mode, index: number) {
      this.showDemo();
      this.selectedIndex = index;
      this.iframeSrcToSet = `${sitchClientUrl}?u=${this.$store.state.userId}&am=${mode.docId}`;
    },
    showDefaultDemo() {
      this.showDemo();
      this.iframeSrcToSet = `https://sitch.app/shotbybri`;
    },
    restart() {
      this.productTapping = false;
      this.cardTapFinished = false;
      this.turnOnPhone = false;
    },
    cardFlip() {
      this.cardFlipped = !this.cardFlipped;
    },
    cardTap() {
      this.scrollIntoViewWithOffset(this.$refs.cardSection as HTMLElement, 50);
      this.productTapping = true;
      clearTimeout(this.animationTimeout);
      this.animationTimeout = window.setTimeout(() => {
        this.productTapping = false;
      }, 2000);
    },
    scrollIntoViewWithOffset(element: HTMLElement, headerOffset: number) {
      const documentTop = document.documentElement.scrollTop || window.scrollY;
      const elementPosition = element.getBoundingClientRect().top + documentTop;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    },
    showDemo() {
      this.restart();
      if (!this.productTapping) {
        this.scrollIntoViewWithOffset(this.$refs.cardSection as HTMLElement, 50);
        this.productTapping = true;
        this.turnOnPhone = true;
        clearTimeout(this.animationTimeout);
        this.animationTimeout = window.setTimeout(() => {
          this.productTapping = false;
          this.cardTapFinished = true;
          this.iframeSrc = this.iframeSrcToSet;
        }, 2000);
      }
    },
    screenTap() {
      if (!this.$data.productTapping) {
        this.$data.productTapping = true;
        window.setTimeout(() => {
          this.$data.productTapping = false;
          this.$data.cardTapFinished = true;
          this.$data.firstTapHappened = true;
        }, 2000);
      }
    },
    onTapButtonPress() {
      this.screenTap();
    },
    prevProduct() {
      this.currProduct--;
      if (this.currProduct < 0) {
        this.currProduct = this.products.length - 1;
      }
    },
    nextProduct() {
      this.currProduct++;
      if (this.currProduct >= this.products.length) {
        this.currProduct = 0;
      }
    },
    assignDeviceIds() {
      this.showAddDevicesFromCsvDialog = false;

      interface DeviceIdAndName {
        id: string;
        name: string;
      }

      if (this.uploadedDeviceCsvFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
          let csvString = event.target?.result as string;

          if (!csvString) {
            showError(`No CSV provided`);
            return;
          }

          const currUser = fbAuth.currentUser;

          if (!currUser) {
            showError(`No logged in user`, null, true);
            return;
          }

          showLoading();

          parse(csvString, {
            header: true,
            transform: function (s: string) {
              return s.trim();
            },
            skipEmptyLines: true,
            complete: (results: any) => {
              let deviceIdsAndNames: DeviceIdAndName[] = results.data;

              const isValid = deviceIdsAndNames.every((deviceIdAndName: DeviceIdAndName) => {
                return deviceIdAndName.id && deviceIdAndName.name;
              });

              if (!isValid || deviceIdsAndNames.length === 0) {
                showError(t.csvIsMalformed);
                hideLoading();
                return;
              }

              const userId = this.$store.state.userId;

              standardApiFetch(endpoints.activateSitchLinks, {
                deviceIdsAndNames,
                userId,
              }).then(
                (response: {
                  successfulResponse: {
                    clientSideUserUpdate: any;
                  };
                }) => {
                  const newGenericIdsBoundToThisAccount = {
                    ...this.$store.state.currUser.genericIdsBoundToThisAccount,
                    ...response.successfulResponse.clientSideUserUpdate,
                  };
                  updateUserLocal({
                    genericIdsBoundToThisAccount: newGenericIdsBoundToThisAccount,
                    dateUpdated: Timestamp.fromMillis(Date.now()),
                  });
                  this.$store.commit('currUserModeGateway', {
                    ...this.$store.state.currUserModeGateway,
                    isSitchLinkActivated: true,
                    dateUpdated: Timestamp.fromMillis(Date.now()),
                  });
                  showNotice(t.yourDevicesHaveBeenLinked);
                  hideLoading();
                }
              );
            },
          });
        };
        reader.readAsText(this.uploadedDeviceCsvFile);
      }
    },
  },
});
